 /************* 
  * forced filter style
  ******************/
  /* #deckgl-wrapper{
    position: relative !important;
} */
#view-default-view{
    /* top: 30px !important; */
}



/*  Task Panel */
.grid-root:hover{
  background-color: transparent;
}
.grid-item{
    background-color: #323F4D !important;
    /* background-color: #E6EEF6 !important; */
}