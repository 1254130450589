.root{
    overflow: hidden;
}

/* FORCED MUI STYLE */

/* Select Input label size */
.css-inhbkp{
    font-size: 12px !important;
}

.loader{
    width: 200px; 
    padding: 15px 0; 
    border-radius: 20px; 
    background-color: #202c39;
    margin: 0 auto;
    box-shadow: 3px 3px 3px #323f4d;
    /* color: darkgreen; */
   
}
.sl-loader-title{
    padding: 0 !important;
}
.sl-loader-title span{
    font-size: 14px;
}

#deckgl-wrapper{
    pointer-events: auto !important;
    user-select: text !important;
    /* cursor: text !important; */
}
#custom-popup{
    padding: 10px 5px;
}

.mapboxgl-popup{
    display: absolute !important;
    left: -10px !important;
    transform: translate(0 , 0) !important;
    max-width: 450px !important;
    z-index: 1000;
    transform: none !important;
}

.mapboxgl-popup-content{
    height: 100%;
    /* width: 30vw !important; */
    overflow-y: scroll;
    cursor: default;
    padding: 15px 10px 15px 10px !important;
    
}
 
.mapboxgl-popup-content td{
    width: 15vw;
}

.updateInput{
    display: block;
    width: 100%;
    height: 38px;
    color: rgb(77, 77, 77);
    font-size: 0.9rem;
    padding: 0 0 0 8px;
    /* border: 1px solid #2ddbac; */
    border: 1.5px solid rgba(190, 190, 190, 0.76);
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(27, 27, 27, 0.151);
    margin: 5px 0 10px 0;
}
.inline-label{
    padding: 5px 10px;
    font-size: 16px;
}
.dateInput{
    display: inline-block;
    width: 125px;
    margin: 0 auto;
    background-color: rgba(250, 235, 215, 0.192);
}
.basic-multi-select{
    box-shadow: 2px 2px 3px rgba(27, 27, 27, 0.151);
    margin: 5px 0 10px 0;
    
}
label{
    font-weight: 500;
    font-size: 18px;
    color: #24242b;
    
}
.radioInput{
      margin: 5px 0 10px 0;
}

.radioLabel{
    display: block;
    margin-top: 20px;
}
input[type='radio']{
    display: inline-block;
    margin-bottom: 20px;
    color: red
}

/* .mapboxgl-popup-content:hover{
    color: green;
} */

/* customPopup page */
/* force dynamic style */
.mapboxgl-popup-close-button{
    font-size: 28px;
    margin-right: 15px;
    margin-top: 5px;
    z-index: 100;
}
.mapboxgl-popup-tip{
    display: none !important;
}
/* .mapboxgl-popup-content{ */
    /* position: static !important; */
    /* top: -10px; */
/* } */
.legend-container{
    position: absolute;
    bottom: 0;
    left: 0;
    /* background-color: #323f4d; */
    background-color: #343a40;
    border-radius: 0 10px 0 0;
    /* color: antiquewhite; */
    color: #119b88;
    color: #faf7f2;
    font-weight: bold;
    font-family: monospace;
}
.popup-row-dt{
    /* max-width: 220px !important; */
    padding-left: 10px !important;
}
.popup-tr{
    outline: 0.5px solid rgba(48, 48, 48, 0.178) !important;
    margin: 1px 0;
    padding: 3px 0 !important; 
    /* border-collapse: collapse; */
}

.toggleBtn{
    padding: 8px 15px;
    margin-bottom: 10px;
    border: 1px solid #24242b56;
    border-radius: 5px;
    font-weight: bold;
    color: rgb(44, 44, 44);
    box-shadow: 2px 2px 3px rgba(27, 27, 27, 0.151);
}

/* Panel */
.MuiContainer-root{
    padding: 0 !important;
}

/* image slider */
.slider-wrapper{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #252121dc;
    z-index: 9;
}
.slider-wrapper span{
    display: none;
}
.slide-container{
    position: relative;
    /* background-color: rgb(48, 49, 49); */
}
.slide-img{
  
    height: 410px;
    width: 410px;
    border-radius: 10px;
    
    /*z-index: 10;
    left: 55%;
    top: 45%;
    transform: translate(-50%, -50%); */
}
.slider-close{
    position: absolute;
    /* top: 18%;
    left: 63%; */
    margin-top: -40px;
}
.slider-content{
    position: absolute;
    /* left: 685px; */
    left: 55%;
    top: 45%;
    transform: translate(-50%, -50%); 

}


@media screen and (max-width: 1225px) {
    .slider-content{

        left: 650px;
        
    
    }
  }

/* Filter By Panel */
.filterBy-dropdown{
    /* line-height: 20px; */
    font-size: 12px;
    padding: 0;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 0 !important;
    box-shadow: 2px 2px 3px rgba(27, 27, 27, 0.151);
}

.filter-label{
    /* background-color: #24242b; */
    display: block;
    margin: 0 35px;
    font-size: 12px;
    padding-left: 1px;
}

/* Table style */
.td-styles{
    box-sizing: 'border-box';
    vertical-align: 'top';
    padding: '0px 4px';
    width: '220px';
    max-width: '450px';
   
}
.state-value{
    font-size: 34px;
    font-weight: bolder;
    margin: 5px auto;
   
}
.stat-row{
    width: 100%;
    color: #319688
    /* background-color: #202c393a; */
}
.stat-card{
    width: 45%;
    background-color: #323f4d;
    border-radius: 5px;
    min-height: 120px;
    padding: 10px 20px;
    margin: 4px 2px;
    box-shadow: 3px 3px 6px #6d7986;;
    /* background-color: #202c393a; */
}
.stat-card-dt{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.stat-card-title{
    font-weight: bold;
    margin: 5px auto;
}
.select input {
    font-size: 10px;
  }


  /************* 
  * forced filter style
  ******************/
  